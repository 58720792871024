(function () {
  'use strict';

  angular
    .module('events.reports')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.reports', {
        abstract: true,
        url: '/:eventId/reports',
        resolve: {
          event: function ($stateParams, Restangular) {
            return Restangular.one('events', $stateParams.eventId).get();
          }
        },
        templateUrl: 'events/reports/reports.tpl.html'
      })
      .state('events.reports.donations', {
        url: '/donations',
        templateUrl: 'events/reports/donations.tpl.html',
        controller: 'DonationsCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.entry_fees', {
        url: '/entry-fees',
        templateUrl: 'events/reports/entry-fees.tpl.html',
        controller: 'EntryFeesCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.disbursements', {
        url: '/disbursements',
        templateUrl: 'events/reports/disbursements.tpl.html',
        controller: 'DisbursementsCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.option', {
        url: '/option',
        templateUrl: 'events/reports/option.tpl.html',
        controller: 'OptionCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.front_end_checks', {
        url: '/front-end-checks',
        templateUrl: 'events/reports/front-end-checks.tpl.html',
        controller: 'FrontEndCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.indemnity', {
        url: '/indemnity',
        templateUrl: 'events/reports/indemnity.tpl.html',
        controller: 'IndemnityCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.dietary', {
        url: '/dietary-requirements',
        templateUrl: 'events/reports/dietary.tpl.html',
        controller: 'DietaryCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.guests', {
        url: '/guests',
        templateUrl: 'events/reports/guests.tpl.html',
        controller: 'GuestsCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.roadbook', {
        url: '/roadbook',
        templateUrl: 'events/reports/roadbook.tpl.html',
        controller: 'RoadbookCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.safety', {
        url: '/safety',
        templateUrl: 'events/reports/safety.tmpl.html',
        controller: 'SafetyCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.virgins', {
        url: '/virgins',
        templateUrl: 'events/reports/virgins.tpl.html',
        controller: 'VirginsCtrl',
        controllerAs: 'vm',
        resolve: {
          isVeterans: function () {
            return false;
          }
        }
      })
      .state('events.reports.veterans', {
        url: '/veterans',
        templateUrl: 'events/reports/veterans.tpl.html',
        controller: 'VirginsCtrl',
        controllerAs: 'vm',
        resolve: {
          isVeterans: function () {
            return true;
          }
        }
      })
      .state('events.reports.medical', {
        url: '/medical',
        templateUrl: 'events/reports/medical.tpl.html',
        controller: 'MedicalCtrl',
        controllerAs: 'vm'
      })
      .state('events.reports.vaccinations', {
        url: '/vaccinations',
        templateUrl: 'events/reports/vaccinations.tpl.html',
        controller: 'VaccinationsCtrl',
        controllerAs: 'vm'
      })
    ;
  }
}());
